import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Home from '../views/Home.vue'
import Vivo from '../views/Vivo.vue'
import Oneplus from '../views/Oneplus.vue'
import Oppo from '../views/Oppo.vue'
import Huawei from '../views/Huawei.vue'
import Mi from '../views/Mi.vue'
import Samsung from '../views/Samsung.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    meta: { title: 'https://dcb.szjtyjy.com' },
    component: Index
  },
  {
    path: '/courseSjdd',
    name: 'home',
    meta: { title: '自启动教程' },
    component: Home
  },
  {
    path: '/vivo',
    name: 'vivo',
    meta: { title: 'VIVO设置教程' },
    component: Vivo
  },
  {
    path: '/oppo',
    name: 'oppo',
    meta: { title: 'OPPO设置教程' },
    component: Oppo
  },
  {
    path: '/oneplus',
    name: 'oneplus',
    meta: { title: '一加设置教程' },
    component: Oneplus
  },
  {
    path: '/huawei',
    name: 'huawei',
    meta: { title: '华为设置教程' },
    component: Huawei
  },
  {
    path: '/mi',
    name: 'mi',
    meta: { title: '小米设置教程' },
    component: Mi
  },
  {
    path: '/samsung',
    name: 'samsung',
    meta: { title: '三星设置教程' },
    component: Samsung
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
