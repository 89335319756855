<template>
  <div class="home">
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  created () {
    window.location.href = 'https://dcb.szjtyjy.com/ims'
  }
}
</script>
<style lang="scss">
</style>
