<template>
  <div class="home">
    <div class="content">
      <div class="title">选择您所使用的机型</div>
      <div class="desc">设置软件自启动教学与后台弹出窗权限</div>
      <div class="brands">
        <router-link to="/vivo" class="brand"><img src="../assets/imgs/vivo.png" alt=""></router-link>
        <router-link to="/oppo" class="brand"><img src="../assets/imgs/oppo.png" alt=""></router-link>
        <router-link to="/oneplus" class="brand"><img src="../assets/imgs/oneplus.png" alt=""></router-link>
        <router-link to="/huawei" class="brand"><img src="../assets/imgs/huawei.png" alt=""></router-link>
        <router-link to="/mi" class="brand"><img src="../assets/imgs/mi.png" alt=""></router-link>
        <router-link to="/samsung" class="brand"><img src="../assets/imgs/samsung.png" alt=""></router-link>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {

    }
  }
}
</script>
<style lang="scss">
.content{
  padding: 30px 10px;
  .title{
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }
  .desc{
    font-size: 14px;
    color: #424752;
    margin: 10px 0 20px;
  }
  .brands{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .brand{
      width: 31%;
      margin-top: 10px;
      box-shadow: 0 6px 10px #ddd;
    }
    .brand img{
      width: 100%;
    }
  }
}
</style>
